const freeTrialError = {
    en: {
        3: ["Invalid trial length."],
        4: ["E-mail is invalid."],
        507: ["Oops.", "You've reached the maximum number of trials."],
        508: [
            "Oops.",
            "There's already an account associated with that email address and our free trial is for new users. <a href='https://totale.rosettastone.com/sign_in'>Sign In</a>."
        ],
        unknown: ["Unknown error. Please try again."],
        invalidPassword: ["Your password must be 6 or more characters, and cannot contain special characters."]
    },
    es: {
        3: ["Invalid trial length."],
        4: ["El correo electrónico parece que no es válido."],
        507: ["Has alcanzado el máximo número de pruebas."],
        508: [
            "a hay una cuenta existente con ese correo electrónico y nuestra prueba gratuita es para nuevos usuarios. <a href='https://totale.rosettastone.com/sign_in?locale=es-419'>Ingresar</a>"
        ],
        unknown: ["Error desconocido. Por favor intentar de nuevo."],
        invalidPassword: ["Contraseña debe tener 6 letras sin caracteres especiales."]
    },
    pt: {
        3: ["Quantidade inválida de avaliações."],
        4: ["Este email é inválido."],
        507: ["Você atingiu o número máximo de avaliações."],
        508: [
            "Já existe uma conta associada a esse endereço de email, e nossa avaliação gratuita destina-se a novos usuários. <a href='https://totale.rosettastone.com/sign_in?locale=pt-BR'>Fazer Login</a>"
        ],
        unknown: ["Erro desconhecido. Tente novamente."],
        invalidPassword: ["A senha deve conter 6 ou mais caracteres, sem caracteres especiais."]
    },
    ko: {
        3: ["무료채험 기간이 올바르지 않습니다."],
        4: ["유효하지 않은 이메일 주소입니다."],
        507: ["앗!", "최대 시행 횟수에 도달했습니다."],
        508: [
            "앗!",
            "해당 이메일 주소와 연결된 계정이 이미 있으며 무료채험은 새 사용자를 위한 것입니다. <a href='https://totale.rosettastone.com/sign_in?locale=ko-KR'>로그인</a>"
        ],
        unknown: ["알 수 없는 오류입니다. 다시 시도하십시오."],
        invalidPassword: ["암호는 6자 이상이어야 하며 특수 문자는 없어야 합니다."]
    }
};

const emailErrors = {
    en: ["E-mail is invalid."],
    es: ["El correo electrónico parece que no es válido."],
    pt: ["Este email é inválido."],
    ko: ["유효하지 않은 이메일 주소입니다."]
};

module.exports = {
    emailErrors,
    freeTrialError
};
