exports.components = {
  "component---src-pages-business-index-jsx": () => import("./../../../src/pages/business/index.jsx" /* webpackChunkName: "component---src-pages-business-index-jsx" */),
  "component---src-pages-business-language-solutions-jsx": () => import("./../../../src/pages/business/language-solutions.jsx" /* webpackChunkName: "component---src-pages-business-language-solutions-jsx" */),
  "component---src-pages-business-pricing-request-complete-jsx": () => import("./../../../src/pages/business/pricing-request-complete.jsx" /* webpackChunkName: "component---src-pages-business-pricing-request-complete-jsx" */),
  "component---src-pages-business-pricing-request-jsx": () => import("./../../../src/pages/business/pricing-request.jsx" /* webpackChunkName: "component---src-pages-business-pricing-request-jsx" */),
  "component---src-pages-education-index-jsx": () => import("./../../../src/pages/education/index.jsx" /* webpackChunkName: "component---src-pages-education-index-jsx" */),
  "component---src-pages-education-pricing-request-complete-jsx": () => import("./../../../src/pages/education/pricing-request-complete.jsx" /* webpackChunkName: "component---src-pages-education-pricing-request-complete-jsx" */),
  "component---src-pages-education-pricing-request-jsx": () => import("./../../../src/pages/education/pricing-request.jsx" /* webpackChunkName: "component---src-pages-education-pricing-request-jsx" */),
  "component---src-pages-index-jsx": () => import("./../../../src/pages/index.jsx" /* webpackChunkName: "component---src-pages-index-jsx" */),
  "component---src-pages-training-centers-index-jsx": () => import("./../../../src/pages/training-centers/index.jsx" /* webpackChunkName: "component---src-pages-training-centers-index-jsx" */),
  "component---src-pages-training-centers-language-solutions-jsx": () => import("./../../../src/pages/training-centers/language-solutions.jsx" /* webpackChunkName: "component---src-pages-training-centers-language-solutions-jsx" */),
  "component---src-pages-training-centers-pricing-request-complete-jsx": () => import("./../../../src/pages/training-centers/pricing-request-complete.jsx" /* webpackChunkName: "component---src-pages-training-centers-pricing-request-complete-jsx" */),
  "component---src-pages-training-centers-pricing-request-jsx": () => import("./../../../src/pages/training-centers/pricing-request.jsx" /* webpackChunkName: "component---src-pages-training-centers-pricing-request-jsx" */),
  "component---src-templates-content-index-jsx": () => import("./../../../src/templates/content/index.jsx" /* webpackChunkName: "component---src-templates-content-index-jsx" */),
  "component---src-templates-resources-index-jsx": () => import("./../../../src/templates/resources/index.jsx" /* webpackChunkName: "component---src-templates-resources-index-jsx" */)
}

